import React, { ReactElement, useMemo } from 'react';

import { AiOutlineInfoCircle } from 'react-icons/ai';
import { RiCloseLine } from 'react-icons/ri';
import { getFacilityIcon } from '../../utils/facilityIcons';
import { formatNumberToPrice } from '../../utils/formatNumber';

import {
  Container,
  AllItemsModalContent,
  ModalHeader,
  ModalBody,
  Content,
} from './styles';

interface AllItemsModalProps {
  handleClose: () => void;
  facilities?: any[];
  staff?: string;
  toys?: any[];
  activities?: any[];
}

const AllItemsModal = ({
  handleClose,
  facilities,
  staff,
  toys,
  activities,
}: AllItemsModalProps): ReactElement => {
  const lists = useMemo(
    () => [
      { title: 'Estrutura', list: facilities },
      { title: 'Brinquedos', list: toys },
      { title: 'Atividades', list: activities },
      // TODO: Experiências adicionais
      // { title: 'Experiências adicionais', list: [] },
    ],
    [],
  );

  const normalize = ({
    id,
    attributes: { name, icon, price } = { name: null, icon: null, price: null },
    name: nameAlt,
    icon: iconAlt,
  }: any): { id: number; name: string; icon?: string, price?: string } => ({
    id,
    name: name || nameAlt,
    icon: icon || iconAlt,
    price,
  });

  return (
    <Container>
      <AllItemsModalContent>
        <ModalHeader>
          <h2>O que a casa oferece</h2>

          <RiCloseLine onClick={handleClose} />
        </ModalHeader>
        <ModalBody>
          {lists.map(({ title, list }) => (
            <Content key={title}>
              <h2>{title}</h2>
              {list?.length ? (
                <ul>
                  {list?.map(normalize)?.map(({
                    id, name, icon, price,
                  }) => (
                    <li key={id}>
                      {getFacilityIcon(icon)}
                      {' '}
                      {name}
                      {price ? ` | ${formatNumberToPrice(price)}` : ''}
                    </li>
                  ))}
                </ul>
              ) : (
                <span>
                  <AiOutlineInfoCircle />
                  Nenhum adicional para este(a) pacote/casa.
                </span>
              )}
            </Content>
          ))}

          <Content>
            <h2>Equipe</h2>
            <ul>
              <li>
                {staff || ''}
              </li>
            </ul>
          </Content>
        </ModalBody>
      </AllItemsModalContent>
    </Container>
  );
};

AllItemsModal.defaultProps = {
  facilities: [],
  staff: '',
  toys: [],
  activities: [],
};

export default AllItemsModal;
