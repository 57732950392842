import { Entity } from '../types/strapi';
import api from './api';

export default {
  async get(): Promise<Entity<any>> {
    try {
      const [parametersResponse] = (
        await api.get('/parameters')
      ).data?.data;
      return parametersResponse;
    } catch {
      throw new Error('Unable to list a party house!');
    }
  },
};
