import React, { ReactElement, useEffect, useState } from 'react';
import moment from 'moment';

import { RiTimeLine, RiCloseLine, RiStarFill } from 'react-icons/ri';

import EmptyHouse from '../../assets/EmptyHouse.svg';

import { packagesService } from '../../services';

import {
  Container,
  PlanModalContent,
  PlanModalContentHeader,
  PartyHouseImage,
  PlanModalContentHeaderTexts,
  PartyHouseMainContent,
  PartyHouseMainContentTexts,
  PartyHouseRating,
  SelectedFiltersList,
  EventDuration,
  FinalValue,
  PartyHouseObservations,
  DetailItems,
  Accommodations,
  MenuItems,
  MenuItemsList,
  MenuItem,
  AccommodationsList,
  AccommodationItem,
  FilterItem,
} from './styles';

interface PlanModalProps {
  handleClose: () => void;
  toggleAllItemsModal: () => void;
  toggleMenuItemsModal: () => void;
  togglePreBookingModal: () => void;
  selectedPackage: string | number;
  facilities: any[],
  houseInfos: any,
}

const PlanModal = ({
  handleClose,
  toggleAllItemsModal,
  toggleMenuItemsModal,
  togglePreBookingModal,
  selectedPackage,
  facilities,
  houseInfos,
}: PlanModalProps): ReactElement => {
  const [packageInformations, setPackageInformations] = useState<any>([]);
  const [storagedFilters, setStoragedFilters] = useState<any>({});

  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    getPlanInformations();

    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);

  async function getPlanInformations(): Promise<void> {
    try {
      const parsedFilters = JSON.parse(sessionStorage.getItem('@App:filters') as any);
      setStoragedFilters(parsedFilters || []);

      const packageInformationsResponse = (await packagesService.getOne(selectedPackage))?.attributes;
      setPackageInformations(packageInformationsResponse);
    } catch {
      // console.log('Error when list a selected package informations.');
    }
  }

  return (
    <Container>
      <PlanModalContent>
        <PlanModalContentHeader>
          <PartyHouseImage src={EmptyHouse} alt="EmptyHouse" />
          <PlanModalContentHeaderTexts>
            <h2>{packageInformations?.name ?? ''}</h2>
            <p>
              {packageInformations?.description ?? ''}
            </p>
          </PlanModalContentHeaderTexts>
          <button type="button" onClick={handleClose}>
            <RiCloseLine />
          </button>
        </PlanModalContentHeader>

        <PartyHouseMainContent>
          <PartyHouseMainContentTexts>
            <h3>
              {houseInfos?.name ?? ''}
              <small>
                <b>
                  {`${houseInfos?.address?.address || ''}, ${
                    houseInfos?.address?.number || ''
                  } - ${
                    houseInfos?.address?.neighborhood || ''
                  }`}
                </b>
                -
                {' '}
                {houseInfos?.address?.city}
              </small>
            </h3>

            <PartyHouseRating>
              <span>
                5,0
                <RiStarFill />
                <RiStarFill />
                <RiStarFill />
                <RiStarFill />
                <RiStarFill />
              </span>
              <small>0 avaliações</small>
            </PartyHouseRating>
          </PartyHouseMainContentTexts>

          <SelectedFiltersList>
            <FilterItem>
              <small>Tipo de festa</small>
              {storagedFilters?.type ?? 'Família'}
            </FilterItem>

            <FilterItem>
              <small>Data da festa</small>
              {moment(storagedFilters?.date).format('DD/MM/YYYY') ?? ''}
            </FilterItem>

            <FilterItem>
              <small>Nº de convidados</small>
              {storagedFilters?.guests ?? '50'}
            </FilterItem>

            <FilterItem>
              <small>Turno</small>
              {storagedFilters?.shift ?? 'Tarde'}
            </FilterItem>
          </SelectedFiltersList>

          <DetailItems>
            <Accommodations>
              <h4>O que terá na sua festa</h4>
              <AccommodationsList>
                {facilities
                  .slice(0, 4)
                  .map(({ id, attributes: { name } }) => (
                    <AccommodationItem key={id}>
                      {name}
                    </AccommodationItem>
                  ))}
              </AccommodationsList>
              <button type="button" onClick={toggleAllItemsModal}>Mostrar todos os itens</button>
            </Accommodations>

            <MenuItems>
              <h4>Cardápio</h4>
              <MenuItemsList>
                {packageInformations?.menus?.data?.length ? packageInformations?.menus?.data
                  .slice(0, 6)
                  .map(({ id, attributes: { name } }: any) => (
                    <MenuItem key={id}>
                      {name}
                    </MenuItem>
                  )) : (
                    <MenuItem>
                      Nenhum cardápio cadastrado.
                    </MenuItem>
                )}
              </MenuItemsList>

              <button type="button" onClick={toggleMenuItemsModal} disabled={!packageInformations?.menus?.data?.length}>Mostrar cardápio completo</button>
            </MenuItems>
          </DetailItems>

          <EventDuration>
            <RiTimeLine />
            Evento com duração de:
            {' '}
            <b>{packageInformations?.duration ?? ''}</b>
          </EventDuration>

          <FinalValue>
            <span>
              Valor final:
              <b>R$0,00</b>
            </span>

            <p>
              {houseInfos?.infoBooking
                  ?? 'Ao realizar sua pré-reserva, você terá até 3 dias úteis para realizar a entrada e confirmar sua reserva de forma definitiva.'}
            </p>
          </FinalValue>

          <button type="button" onClick={togglePreBookingModal}>Realizar minha pré-reserva!</button>
        </PartyHouseMainContent>

        <PartyHouseObservations>
          <h5>Observações</h5>
          <p>
            {packageInformations?.observation ?? ''}
          </p>
        </PartyHouseObservations>
      </PlanModalContent>
    </Container>
  );
};

export default PlanModal;
