import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.alternativeCold01};
    min-height: 513px;
    margin-bottom: 28px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 0 0 10px 10px;

    > h1 {
      color: ${theme.colors.neutral11};
      font-size: 4.2rem;
      line-height: 4.8rem;
      font-weight: 900;
      text-align: center;
      max-width: 1130px;
      width: 100%;
    }

    > small {
      color: ${theme.colors.neutral11};
      font-size: 1.8rem;
      line-height: 1.8rem;
      font-weight: 700;
      opacity: 0.5;
      margin-bottom: 32px;
    }

    @media (max-width: 1340px) {
      > h1 {
        font-size: 3rem;
        font-weight: 700;
        line-height: 3.2rem;
        max-width: 607px;
      }

      > small {
        font-size: 1.6rem;
        line-height: 1.6rem;
        margin-top: 46px;
      }
    }

    @media (max-width: 700px) {
      min-height: unset;
      margin-top: 45px;
      padding-bottom: 36px;
        
      > h1 {
        font-size: 2.8rem;
        max-width: 296px;
        font-weight: 900;
      }
    }

    @media (max-width: 410px) {
      > small {
        text-align: center;
        font-size: 1.2rem;
        line-height: 1.8rem;
      }
    }
  `};
`;

export const MainFilters = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;

    margin-top: 44px;

    > button {
      width: 58px;
      height: 58px;
      border-radius: 100px;
      background-color: ${theme.colors.alternativeHot01};
      color: ${theme.colors.neutral11};
      border: 1px solid transparent;

      transition: ${theme.transitions.default};

      &:hover {
        background-color: transparent;
        border-color: ${theme.colors.alternativeHot01};
        color: ${theme.colors.alternativeHot01};
      }
    }
  `};

  @media (max-width: 1340px) {
    flex-wrap: wrap;
    max-width: 720px;
    column-gap: 12px;

    > button {
      max-width: 199px;
      width: 100%;
    }
  }

  @media (max-width: 700px) {
    flex-wrap: unset;
    flex-direction: column;
    width: 100%;
    padding: 0 32px;

    > button {
      max-width: unset;
      height: 60px;
    }
  }
`;

export const FilterControl = styled.div`
  ${({ theme }) => css`
    position: relative;
    
    display: flex;
    align-items: center;

    > span {
      position: absolute;
      right: 25px;
      top: 50%;
      transform: translateY(-50%);
      color: ${theme.colors.alternativeCold01};

      display: flex;
      align-items: center;

      > svg {
        font-size: 2rem;
      }
    }

    > select {
      -webkit-appearance: none;
      -moz-appearance: none;
    }

    > input, select {
      border-radius: 100px;
      height: 58px;
      min-width: 199px;
      max-width: 199px;
      border: none;
      padding: 0 24px;
      font-size: 1.4rem;
      line-height: 1.8rem;
      font-weight: 700;
      margin-right: 12px;
      color: ${theme.colors.alternativeCold01};

      &::placeholder {
        color: ${theme.colors.alternativeCold01};

        transition: ${theme.transitions.default};
      }

      &:focus {
        &::placeholder {
          color: ${theme.colors.neutral90};
        }
      }

      @media (max-width: 1340px) {
        margin-bottom: 12px;
        margin-right: 0;
      }

      @media (max-width: 700px) {
        max-width: unset;
        width: 100%;
        height: 64px;
      }

      &.error {
        border: 1px solid red;
      }
    }
  `};
`;
