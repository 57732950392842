import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);

  width: 100%;
  height: 100vh;
  z-index: 1000;

  display: flex;
  align-items: center;
`;

export const PlanModalContent = styled.div`
  ${({ theme }) => css`
    max-width: 787px;
    width: 100%;
    background-color: ${theme.colors.neutral11};
    margin: 0 auto;
    padding: 0 44px 44px 44px;
    border-radius: 24px;

    position: absolute;
    top: 30px;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    max-height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0px;
      background-color: white;
    }
  `};
`;

export const PlanModalContentHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: flex-start;

    padding: 44px 0 32px 0;
    background-color: ${theme.colors.neutral11};

    position: sticky;
    z-index: 1000;
    top: 0;

    > button {
      margin-left: auto;
      background-color: transparent;
      cursor: pointer;

      > svg {
        color: ${theme.colors.alternativeCold01};
        font-size: 2.8rem;
      }
    }
  `};
`;

export const PartyHouseImage = styled.img`
  height: 60px;
  width: 100px;
  border-radius: 8.2px;
  margin-right: 24px;
`;

export const PlanModalContentHeaderTexts = styled.div`
  ${({ theme }) => css`
    > h2 {
      color: ${theme.colors.alternativeCold01};
      font-weight: 900;
      font-size: 2.8rem;
      line-height: 3.2rem;
      margin-bottom: 8px;
    }

    > p {
      color: ${theme.colors.neutral80};
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 2rem;
    }
  `};
`;

export const PartyHouseMainContent = styled.div`
  ${({ theme }) => css`
    padding: 32px 0 24px 0;
    border-top: 1px solid ${theme.colors.neutral10};

    > button {
      background-color: ${theme.colors.alternativeCold01};
      width: 100%;
      border-radius: 10px;
      height: 50px;
      color: ${theme.colors.neutral11};
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 1.8rem;
      cursor: pointer;

      transition: ${theme.transitions.default};

      &:hover {
        opacity: 0.8;
      }
    }
  `};
`;

export const PartyHouseMainContentTexts = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    margin-bottom: 24px;

    > h3 {
      display: flex;
      flex-direction: column;
      
      color: ${theme.colors.neutral80};
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 2.8rem;

      > small {
        font-size: 1.4rem;
        line-height: 1.8rem;
        font-weight: normal;
        margin-top: 8px;

        > b {
          margin-right: 4px;
          font-weight: 700;;
        }
      }
    }
  `};
`;

export const SelectedFiltersList = styled.ul`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
`;

export const FilterItem = styled.li`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
  
    background: rgba(64, 78, 237, 0.05);
    border-radius: 10px;
    color: ${theme.colors.alternativeCold01};
    font-size: 1.4rem;
    line-height: 1.8rem;
    flex: 1;
    padding: 15px 18px;

    > small {
      font-weight: 700;
    }

    :not(:last-child) {
      margin-right: 12px;
    }
  `};
`;

export const PartyHouseObservations = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.neutral10};
    border-radius: 10px;
    padding: 24px;

    > h5 {
      color: ${theme.colors.neutral80};
      font-size: 1.4rem;
      line-height: 1.8rem;
      font-weight: 700;
      margin-bottom: 12px;
    } 

    > p {
      color: ${theme.colors.neutral80};
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  `};
`;

export const EventDuration = styled.div`
  ${({ theme }) => css`
    background: rgba(255, 204, 77, 0.05);
    padding: 14px 0;
    color: ${theme.colors.alternativeHot03};
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;
    border-radius: 10px;
    margin: 24px 0 16px 0;

    display: flex;
    align-items: center;
    justify-content: center;

    > b {
      margin-left: 3px;
    }

    > svg {
      margin-right: 12px;
    }
  `};
`;

export const FinalValue = styled.div`
  ${({ theme }) => css`
    background: rgba(236, 107, 30, 0.05);
    padding: 22px 32px;
    margin-bottom: 16px;

    display: flex;
    align-items: center;

    > span {
      display: flex;
      flex-direction: column;
      
      color: ${theme.colors.alternativeHot01};
      font-size: 1.6rem;
      line-height: 2rem;
      font-weight: 400;

      > b {
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 2.8rem;
      }
    }

    > p {
      max-width: 485px;
      text-align: center;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 2rem;
      color: ${theme.colors.alternativeHot01};
      margin: 0 auto;
    }
  `};
`;

export const PartyHouseRating = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
  
    > span {
      color: ${theme.colors.neutral80};
      font-weight: 700;
      font-size: 1.4rem;
      line-height: 1.8rem;

      display: flex;
      align-items: center;

      > svg {
        color: ${theme.colors.alternativeHot05};

        :first-child {
          margin-left: 10px;
        }

        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }

    > small {
      color: ${theme.colors.neutral30};
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
      margin-top: 14px;
      align-self: flex-end;
    }
  `};
`;

export const DetailItems = styled.div`
  ${({ theme }) => css`
    display: flex;
  
    h4 {
      color: ${theme.colors.neutral80};
      font-size: 1.8rem;
      line-height: 1.8rem;
      font-weight: 700;
      margin-bottom: 24px;
    }

    button {
      max-width: 214px;
      border: 1px solid rgba(64, 78, 237, 0.2);
      border-radius: 10px;
      height: 46px;
      color: ${theme.colors.alternativeCold01};
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 1.8rem;
      background-color: transparent;
      margin-top: 14px;

      transition: ${theme.transitions.default};

      &:hover {
        opacity: 0.8;
      }
    }
  `};
`;

export const AccommodationsList = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const AccommodationItem = styled.li`
  ${({ theme }) => css`
    background: rgba(64, 78, 237, 0.05);
    border-radius: 50px;
    height: 36px;
    padding: 10px 20px;
    color: ${theme.colors.alternativeCold01};
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.6rem;

    display: flex;
    align-items: center;
    align-self: start;
    justify-content: center;

    :not(:last-child) {
      margin-bottom: 14px;
    }
  `}
`;

export const Accommodations = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  > button {
    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
`;

export const MenuItemsList = styled.ul``;

export const MenuItem = styled.li`
  ${({ theme }) => css`
    color: ${theme.colors.alternativeCold01};
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 400;

    :not(:last-child) {
      margin-bottom: 14px;
    }
  `};  
`;
