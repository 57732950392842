import { Entity } from '../types/strapi';
import api from './api';

interface FormInputValuesProps {
  name: string;
  social: {
    instagram: string;
    phone?: string;
  };
  referEmail: string;
  referName: string;
  status: string;
}

export default {
  async create(data: FormInputValuesProps): Promise<Entity<any>> {
    try {
      return (
        await api.post('/leads', {
          data,
        })
      ).data?.data;
    } catch {
      throw new Error('Unable to list a party house!');
    }
  },
};
