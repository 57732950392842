import React, {
  useEffect, useMemo, useState,
} from 'react';
import GoogleMapReact from 'google-map-react';
import { Link, useParams } from 'react-router-dom';
import {
  RiMagicFill,
  RiArrowLeftSLine,
  RiStarFill,
  RiGroupLine,
  RiUserSmileLine,
  RiCarLine,
  RiMergeCellsHorizontal,
  RiSearchLine,
  RiInformationLine,
} from 'react-icons/ri';

import { getMonthName } from '../../utils/calendarFormatters';

import Footer from '../../components/Footer';
import PlanModal from '../../components/PlanModal';
import AllItemsModal from '../../components/AllItemsModal';
import MenuItemsModal from '../../components/MenuItemsModal';
import PreBookingModal from '../../components/PreBookingModal';
import PreBookingConfirmationModal from '../../components/PreBookingConfirmationModal';

import Baloon from '../../assets/Baloon.png';
import EmptyHouse from '../../assets/EmptyHouse.svg';

import { housesService, filtersService, packagesService } from '../../services';

import {
  Container,
  LeftContent,
  RightMap,
  ReservationDetailsHeader,
  AvailableDate,
  AboutPartyHouse,
  AboutPartyHouseHeader,
  AboutPartyHouseTitles,
  PartyHouseRating,
  RatingStars,
  PartyHouseCapacity,
  PartyHouseCapacityItem,
  PartyHouseCapacityTexts,
  PartyHouseDescription,
  PartyHouseFacilities,
  PricesAndConditions,
  PreBookingWarning,
  PreBookingButton,
  FacilitiesItem,
  FacilitiesList,
  AboutPartyHousePhotos,
  MainPhoto,
  PhotosGrid,
  PhotoGridRow,
  PhotoGridItem,
  PackagesList,
  PackageItem,
  PackageItemTexts,
  PackageItemContent,
  PackageItemPrice,
  PricesAndConditionsFilters,
  PriceAndConditionFilter,
  PackageItemRadio,
  // PaymentConditions,
  // PaymentSectionBox,
  // PaymentMethodsRow,
  // PaymentIconBox,
  CustomMarker,
  Marker,
  MoreInfosTooltip,
  ButtonPreBooking,
  WarningTooltip,
  EmptyBox,
} from './styles';
import { getFacilityIcon } from '../../utils/facilityIcons';
import { HouseSearchFilter } from '../../types/house';

interface UrlParams {
  id: number | string | any;
}

const MapMarker = ({ text = '', address = '' }: any): any => {
  const [visibleDetails, setVisibleDetails] = useState<boolean>(false);

  return (
    <CustomMarker onClick={() => setVisibleDetails(!visibleDetails)}>
      <Marker src={Baloon} alt={text} />
      {visibleDetails && (
        <MoreInfosTooltip>
          {text}
          <small>{address}</small>

          <button type="button">Fechar</button>
        </MoreInfosTooltip>
      )}
    </CustomMarker>
  );
};

const HouseDetail: React.FC = () => {
  const { id: partyHouseId } = useParams() as UrlParams;
  const [activePackage, setActivePackage] = useState<number | any>(null);
  const [visiblePlanModal, setVisiblePlanModal] = useState<boolean>(false);
  const [visibleAllItemsModal, setVisibleAllItemsModal] = useState<boolean>(false);
  const [visibleMenuItemsModal, setVisibleMenuItemsModal] = useState<boolean>(false);
  const [visiblePreBookingModal, setVisiblePreBookingModal] = useState<boolean>(false);
  const [visiblePreBookingConfirmationModal, setVisiblePreBookingConfirmationModal] = useState<boolean>(false);
  const [partyHouseInfos, setPartyHouseInfos] = useState([] as any);
  const [filtersList, setFiltersList] = useState<any>([]);
  const [packagesList, setPackagesList] = useState<any>([]);
  const [storagedFilters, setStoragedFilters] = useState<any>({});
  // const baseURL = 'https://api.nivis.bravosul.app' || '';
  const selectedDate = new Date(storagedFilters?.date) || '';

  const [filterValues, setFilterValues] = useState({
    type: 'Escola',
    date: '2022-09-07',
    guests: '100',
    shift: 'Tarde',
    city: 'Porto Alegre',
  } as HouseSearchFilter);

  useEffect(() => {
    window.scrollTo(0, 0);
    getHouseDetails();
    getFilters();
    getPackages();
  }, []);

  async function getHouseDetails(): Promise<void> {
    try {
      setPartyHouseInfos(await housesService.getOne(partyHouseId));
    } catch {
      // console.log('Unable to list a party house!');
    }
  }

  const facilities = useMemo<Array<any>>(
    () => partyHouseInfos?.attributes?.facilities?.data || [],
    [partyHouseInfos],
  );

  function scrollToRef(ref: any): any {
    if (ref) {
      document.getElementById(ref)?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  const getFilters = async () : Promise<void> => {
    try {
      const filtersResponse = await filtersService.get();
      setFiltersList(filtersResponse?.attributes?.value || []);
    } catch {
      // console.log('Unable to list a filters from home!');
    }
  };

  const getPackages = async () : Promise<void> => {
    try {
      const parsedFilters = JSON.parse(sessionStorage.getItem('@App:filters') as any);
      setStoragedFilters(parsedFilters || []);

      if (parsedFilters) {
        setPackagesList(await packagesService.get(parsedFilters));
        return;
      }

      setPackagesList(await packagesService.get(filterValues));
    } catch {
      // console.log('Unable to list a filters from home!');
    }
  };

  function handleInputChange(event: any): void {
    const { name, value } = event?.target;

    setFilterValues({
      ...filterValues,
      [name]: value,
    });
  }

  function handleFilterChange(): any {
    sessionStorage.setItem('@App:filters', JSON.stringify(filterValues));
    getPackages();
  }

  return (
    <>
      <Container>
        <LeftContent>
          <ReservationDetailsHeader>
            <Link to="/">
              <RiArrowLeftSLine />
              Voltar para lista
            </Link>

            {storagedFilters?.date ? (
              <AvailableDate>
                <small>Disponível no dia</small>
                <b>
                  {`${new Date(selectedDate.setHours(selectedDate.getHours() + 3))?.getDate()} de ${getMonthName(selectedDate)} de ${selectedDate?.getFullYear()}`}
                </b>
              </AvailableDate>
            ) : ''}
            <button type="button" onClick={() => scrollToRef('plansSection')}>
              <RiMagicFill />
              Realizar minha pré-reserva!
            </button>
          </ReservationDetailsHeader>

          <AboutPartyHouse>
            <AboutPartyHousePhotos>
              <MainPhoto
                src={EmptyHouse}
                alt={
                  partyHouseInfos?.attributes?.cover?.data?.attributes
                    ?.alternativeText || ''
                }
              />
              <PhotosGrid>
                <PhotoGridRow>
                  {partyHouseInfos?.attributes?.media?.data !== null ? (
                    partyHouseInfos?.attributes?.media.data.slice(0, 2).map((item: any) => (
                      <PhotoGridItem
                        key={item?.id}
                        // src={`${baseURL}${item?.attributes?.url}`}
                        alt={item?.attributes?.alternativeText}
                      />
                    ))
                  ) : (
                    <PhotoGridItem src={EmptyHouse} />
                  )}
                </PhotoGridRow>

              </PhotosGrid>
            </AboutPartyHousePhotos>

            <AboutPartyHouseHeader>
              <AboutPartyHouseTitles>
                <h1>{partyHouseInfos?.attributes?.name || ''}</h1>
                <small>
                  <b>
                    {`${partyHouseInfos?.attributes?.address?.address || ''}, ${
                      partyHouseInfos?.attributes?.address?.number || ''
                    } - ${
                      partyHouseInfos?.attributes?.address?.neighborhood || ''
                    }`}
                  </b>
                  -
                  {' '}
                  {partyHouseInfos?.attributes?.address?.city}
                </small>
              </AboutPartyHouseTitles>
              <PartyHouseRating>
                <RatingStars>
                  <span>{partyHouseInfos?.attributes?.rating || '5,0'}</span>
                  <RiStarFill />
                  <RiStarFill />
                  <RiStarFill />
                  <RiStarFill />
                  <RiStarFill />
                </RatingStars>
                <small>
                  {partyHouseInfos?.attributes?.ratingCount || '0'}
                  {' '}
                  Avaliações
                </small>
              </PartyHouseRating>
            </AboutPartyHouseHeader>

            <PartyHouseCapacity>
              <PartyHouseCapacityItem>
                <RiGroupLine />
                <PartyHouseCapacityTexts>
                  <small>Capacidade</small>
                  <b>
                    {partyHouseInfos?.attributes?.capacity?.adults || '0'}
                    {' '}
                    Pessoas
                  </b>
                </PartyHouseCapacityTexts>
              </PartyHouseCapacityItem>

              <PartyHouseCapacityItem>
                <RiUserSmileLine />
                <PartyHouseCapacityTexts>
                  <small>Crianças</small>
                  <b>
                    {partyHouseInfos?.attributes?.capacity?.children || '0'}
                    {' '}
                    crianças
                  </b>
                </PartyHouseCapacityTexts>
              </PartyHouseCapacityItem>

              <PartyHouseCapacityItem>
                <RiCarLine />
                <PartyHouseCapacityTexts>
                  <small>Estacionamento</small>
                  <b>
                    {partyHouseInfos?.attributes?.capacity?.parkingSpaces
                      || '0'}
                    {' '}
                    vagas
                  </b>
                </PartyHouseCapacityTexts>
              </PartyHouseCapacityItem>

              <PartyHouseCapacityItem>
                <RiMergeCellsHorizontal />
                <PartyHouseCapacityTexts>
                  <small>Área total</small>
                  <b>
                    {partyHouseInfos?.attributes?.capacity?.totalArea || '0'}
                    {' '}
                    m²
                  </b>
                </PartyHouseCapacityTexts>
              </PartyHouseCapacityItem>
            </PartyHouseCapacity>

            <ButtonPreBooking>
              <button type="button">
                <RiMagicFill />
                Realizar minha pré-reserva!
              </button>
            </ButtonPreBooking>

            <PartyHouseDescription>
              {partyHouseInfos?.attributes?.description || ''}
            </PartyHouseDescription>

            {/* TO DO: Include facilities list to house detail */}
            {facilities.length > 0 && (
              <PartyHouseFacilities>
                <h3>O que a casa oferece</h3>

                <FacilitiesList>
                  {facilities
                    .slice(0, 6)
                    .map(({ id, attributes: { name, icon } }) => (
                      <FacilitiesItem key={id}>
                        {getFacilityIcon(icon)}
                        {' '}
                        {name}
                      </FacilitiesItem>
                    ))}
                </FacilitiesList>

                <button
                  type="button"
                  onClick={() => setVisibleAllItemsModal(true)}
                  disabled={!facilities.length}
                >
                  Mostrar todos os itens
                </button>
              </PartyHouseFacilities>
            )}
          </AboutPartyHouse>

          <PricesAndConditions id="plansSection">
            <h2>Pacotes e preços</h2>
            {/* <p>
              Nos diga algumas informações sobre sua festa para as melhores
              ofertas da
              {' '}
              <b>{partyHouseInfos?.attributes?.name || ''}</b>
            </p> */}

            <PricesAndConditionsFilters>
              <PriceAndConditionFilter>
                <small>Tipo de festa</small>
                <select onChange={(e) => handleInputChange(e)} name="type">
                  <option value="" disabled>Tipo de Festa</option>
                  {filtersList?.typePackage?.map((type: any) => (
                    <option value={type} key={type}>{type}</option>
                  ))}
                  ;
                </select>
              </PriceAndConditionFilter>
              <PriceAndConditionFilter>
                <small>Data da festa</small>
                <input
                  name="date"
                  type="date"
                  placeholder="Data da festa"
                  onChange={(e) => handleInputChange(e)}
                  defaultValue={filterValues?.date || ''}
                />
              </PriceAndConditionFilter>
              <PriceAndConditionFilter>
                <small>Nº de convidados</small>
                <select onChange={(e) => handleInputChange(e)} name="guests">
                  <option value="" disabled>Nº de convidados</option>
                  {filtersList?.capacity?.map((capacity: any) => (
                    <option value={capacity} key={capacity}>{capacity}</option>
                  ))}
                  ;
                </select>
              </PriceAndConditionFilter>
              <PriceAndConditionFilter>
                <small>Turno</small>
                <select onChange={(e) => handleInputChange(e)} name="shift">
                  <option value="" disabled>Turno</option>
                  {filtersList?.shifts?.map((shift: any) => (
                    <option value={shift?.name} key={shift?.value}>{shift?.name}</option>
                  ))}
                  ;
                </select>
              </PriceAndConditionFilter>
              <button type="button" onClick={handleFilterChange}>
                <RiSearchLine />
              </button>
            </PricesAndConditionsFilters>

            {Object.keys(storagedFilters)?.length !== 0 && (
              <p>
                Exibindo planos e condições para uma festa no dia
                {' '}
                <b>
                  {`${new Date(selectedDate.setHours(selectedDate.getHours() + 3))?.getDate()} de ${getMonthName(selectedDate)} de ${selectedDate?.getFullYear()}` ?? ''}
                  , com
                  {' '}
                  {storagedFilters?.guests ?? ''}
                  {' '}
                  convidados:
                </b>
              </p>
            )}

            <PackagesList>
              {packagesList?.length ? packagesList?.map(
                (packageItem: any) => {
                  const packageDetails = { ...packageItem?.attributes };

                  return (
                    <PackageItem
                      onClick={() => setActivePackage(packageItem?.id)}
                      active={activePackage === packageItem?.id}
                      key={packageItem?.id}
                    >
                      <PackageItemRadio>
                        <span />
                      </PackageItemRadio>
                      <PackageItemTexts>
                        <h4>{packageDetails?.name || ''}</h4>
                        <p>{packageDetails?.description || ''}</p>
                      </PackageItemTexts>

                      <PackageItemContent>
                        <PackageItemPrice>
                          <b>R$0,00</b>
                          {/* <small>12x de R$0,00</small> */}
                        </PackageItemPrice>
                        <button
                          type="button"
                          onClick={() => setVisiblePlanModal(true)}
                        >
                          Saiba mais
                        </button>
                      </PackageItemContent>
                    </PackageItem>
                  );
                },
              ) : (
                <EmptyBox>
                  <p>Nenhum plano disponível para esta casa de festas na data selecionada.</p>
                </EmptyBox>
              )}
            </PackagesList>

            <PreBookingWarning>
              <p>
                {partyHouseInfos?.attributes?.infoBooking
                  || 'Ao realizar sua pré-reserva, você terá até 3 dias úteis para realizar a entrada e confirmar sua reserva de forma definitiva.'}
              </p>

              <PreBookingButton>
                {!activePackage && (
                  <WarningTooltip>
                    <RiInformationLine />
                    Selecione um plano para realizar uma pré-reserva!
                  </WarningTooltip>
                )}
                <button
                  type="button"
                  disabled={!activePackage}
                  onClick={() => setVisiblePreBookingModal(true)}
                >
                  <RiMagicFill />
                  Realizar minha pré-reserva!
                </button>
              </PreBookingButton>
            </PreBookingWarning>

            {/* <PaymentConditions>
              <h2>Condições de pagamento</h2>

              <PaymentSectionBox>
                <small>Meios de pagamento que a casa aceita:</small>
                <PaymentMethodsRow>
                  <PaymentIconBox>
                    <img src={Pix} alt="Pix" />
                  </PaymentIconBox>
                  <PaymentIconBox>
                    <img src={Boleto} alt="Boleto" />
                  </PaymentIconBox>
                  <PaymentIconBox>
                    <img src={MasterCard} alt="MasterCard" />
                  </PaymentIconBox>
                  <PaymentIconBox>
                    <img src={Visa} alt="Visa" />
                  </PaymentIconBox>
                  <PaymentIconBox>
                    <img src={Elo} alt="Elo" />
                  </PaymentIconBox>
                  <PaymentIconBox>
                    <img src={Amex} alt="Amex" />
                  </PaymentIconBox>
                  <PaymentIconBox>
                    <img src={DinersClub} alt="DinersClub" />
                  </PaymentIconBox>
                  <PaymentIconBox>
                    <img src={PayPal} alt="PayPal" />
                  </PaymentIconBox>
                  <PaymentIconBox>
                    <img src={BanriCompras} alt="BanriCompras" />
                  </PaymentIconBox>
                  <PaymentIconBox>
                    <img src={PicPay} alt="PicPay" />
                  </PaymentIconBox>
                </PaymentMethodsRow>
              </PaymentSectionBox>
            </PaymentConditions> */}
          </PricesAndConditions>
        </LeftContent>
        <RightMap>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyC7Tqie9LkPP8w438r08Ij5Wy21o9XjAEw',
            }}
            defaultCenter={{
              lat: partyHouseInfos?.attributes?.address?.lat ?? -30.0178008,
              lng: partyHouseInfos?.attributes?.address?.lng ?? -51.1986702,
            }}
            defaultZoom={13}
          >
            <MapMarker
              lat={partyHouseInfos?.attributes?.address?.lat}
              lng={partyHouseInfos?.attributes?.address?.lng}
              text={partyHouseInfos?.attributes?.name}
              address={partyHouseInfos?.attributes?.address?.address}
            />
          </GoogleMapReact>
        </RightMap>

        {visiblePlanModal && (
          <PlanModal
            handleClose={() => setVisiblePlanModal(false)}
            toggleAllItemsModal={() => setVisibleAllItemsModal(!visibleAllItemsModal)}
            toggleMenuItemsModal={() => setVisibleMenuItemsModal(!visibleMenuItemsModal)}
            togglePreBookingModal={() => setVisiblePreBookingModal(!visiblePreBookingModal)}
            selectedPackage={activePackage}
            facilities={facilities}
            houseInfos={partyHouseInfos?.attributes}
          />
        )}

        {visibleAllItemsModal && (
          <AllItemsModal
            handleClose={() => setVisibleAllItemsModal(false)}
            facilities={facilities}
            staff={partyHouseInfos?.attributes?.staff || []}
            toys={partyHouseInfos?.attributes?.toys?.data || []}
            activities={partyHouseInfos?.attributes?.activities?.data || []}
          />
        )}

        {visibleMenuItemsModal && (
          <MenuItemsModal
            handleClose={() => setVisibleMenuItemsModal(false)}
            selectedPackage={activePackage}
          />
        )}

        {visiblePreBookingModal && (
          <PreBookingModal
            handleClose={() => setVisiblePreBookingModal(false)}
            handlePreBookingConfirmation={() => setVisiblePreBookingConfirmationModal(true)}
            selectedPackage={activePackage}
            houseInfos={partyHouseInfos?.attributes}
          />
        )}

        {visiblePreBookingConfirmationModal && (
          <PreBookingConfirmationModal
            handleClose={() => setVisiblePreBookingConfirmationModal(false)}
            selectedPackage={activePackage}
            houseInfos={partyHouseInfos?.attributes}
          />
        )}
      </Container>
      <Footer />
    </>
  );
};

export default HouseDetail;
