import styled, { css } from 'styled-components';

export const Logo = styled.a`
  > img {
    width: 86.57px;

    @media (max-width: 700px) {
      width: 68px;
    }
  }
`;

export const Container = styled.div`
  ${({ theme }) => css`
    height: 80px;
    padding: 32px;
    background-color: ${theme.colors.alternativeCold01};

    display: flex;
    align-items: center;
    justify-content: space-between;

    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1000;

    @media (max-width: 700px) {
      height: 60px;
    }
  `};
`;

export const RightActions = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    > button {
      background-color: transparent;
      color: ${theme.colors.neutral11};
      font-weight: 700;
      font-size: 1.4rem;
      line-height: 1.8rem;
      margin-right: 24px;

      @media (max-width: 700px) {
        display: none;
      }
    }
  `};
`;

export const MainButtons = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    @media (max-width: 700px) {
      flex-direction: row-reverse;
    }

    &::before {
      content: '';
      display: block;
      width: 1px;
      height: 24px;
      background-color: ${theme.colors.neutral11};
      opacity: 0.4;
      margin-right: 24px;

      @media (max-width: 700px) {
        display: none;
      }
    }

    > button {
      height: 38px;
      border-radius: 100px;
      color: ${theme.colors.neutral11};
      font-weight: 700;
      font-size: 1.4rem;
      line-height: 1.8rem;
      transition: ${theme.transitions.default};
      border: 1px solid transparent;

      &:first-child {
        padding: 10px 16px;
        background-color: transparent;
        border: 1px solid ${theme.colors.neutral11};

        &:hover {
          background-color: ${theme.colors.neutral11};
          color: ${theme.colors.neutral90};
        }

        @media (max-width: 700px) {
          padding: 0;
          border: none;

          &:hover {
            background-color: transparent;
            color: ${theme.colors.neutral11};
          }
        }
      }

      &:last-child {
        margin-left: 12px;
        padding: 10px 20px;

        @media (max-width: 700px) {
          margin-right: 12px;
        }
      }
    }
  `};
`;

export const PrimaryButton = styled.button`
  ${({ theme }) => css`
    background-color: ${theme.colors.alternativeHot01};

    &:hover {
      background-color: transparent;
      color: ${theme.colors.alternativeHot01};
      border-color: ${theme.colors.alternativeHot01};
    }
  `};
`;
