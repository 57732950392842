import React, { useEffect, useState } from 'react';

import Hero from '../../components/Hero';
import IndicationHero from '../../components/IndicationHero';
import Footer from '../../components/Footer';

import {
  Container,
  PartyHouseList,
  PartyHouseCardsList,
  PartyHouseListTab,
} from './styles';
import PartyHouseLargeCard from '../../components/PartyHouseLargeCard';

import { housesService } from '../../services';
import { HouseSearchFilter } from '../../types/house';
import { EmptyBox } from '../HouseDetail/styles';

const Home: React.FC = () => {
  const [partyHouses, setPartyHouses] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getPartyHouses();
  }, []);

  const getPartyHouses = async (filter?: HouseSearchFilter) : Promise<void> => {
    setIsLoading(true);

    if (filter) {
      sessionStorage.setItem('@App:filters', JSON.stringify(filter));
    }

    try {
      // console.log((await housesService.get(filter)));
      setPartyHouses((await housesService.get(filter)) || []);
    } catch {
      // console.log('Unable to list party houses!');
    }

    setIsLoading(false);
  };

  return (
    <Container>
      <Hero search={getPartyHouses} />

      <PartyHouseList>
        <PartyHouseListTab>
          <b>{partyHouses?.length}</b>
          {' '}
          Casas encontradas
        </PartyHouseListTab>

        <PartyHouseCardsList>
          {partyHouses?.length && !isLoading ? partyHouses.map((item: any) => (
            <PartyHouseLargeCard
              key={item?.id}
              visible={false}
              item={item}
            />
          )) : isLoading && !partyHouses?.length ? (
            <EmptyBox>
              <p>
                Carregando lista de casa de festas...
              </p>
            </EmptyBox>
          ) : (
            <EmptyBox>
              <p>
                Que pena, ainda não chegamos na sua cidade 🙁. Nos siga no insta @nivis.eventos e acompanhe as
                novidades!
              </p>
            </EmptyBox>
          )}
        </PartyHouseCardsList>
      </PartyHouseList>

      <IndicationHero />
      <Footer />
    </Container>
  );
};

export default Home;
