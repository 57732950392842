import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }
  
  html {
    font-size: 62.5%; // 1rem = 10px
    height: 100%;
    min-height: -webkit-fill-available;
    font-family: 'Cera Round', Helvetica, Arial, sans-serif;
    -webkit-tap-highlight-color: transparent;
    /* background-color: #404EED; */
  }

  button, input, select {
    font-family: 'Cera Round', Helvetica, Arial, sans-serif;
    font-size: 1.4rem;
  }

  body {
    height: auto;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
    margin: 0 auto;
  }

  body, #root {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    position: relative;

    @media (min-width: 1430px) {
      max-width: 192rem;
      width: 100%;
      margin: 0 auto;
    }
    
    display: flex;
    flex-direction: column;
  }

  #root {
    height: 100%;
    min-height: -webkit-fill-available;
  }

  input, div, li, button, a, textarea, select {
    -webkit-tap-highlight-color: transparent; 
  }

  a {
    text-decoration: none;
    background: none;
    cursor: pointer;
    border: 0;
  }

  button {
    cursor: pointer;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ul {
    list-style: none;
    padding: 0;
    text-align: left;
  }

  @font-face {
    font-family: 'Cera Round';
    font-style: normal;
    font-weight: 100;
    src: local('Cera Round Pro Thin'), url('/fonts/CeraRoundProThin.woff') format('woff');
  }
    
  @font-face {
    font-family: 'Cera Round';
    font-style: normal;
    font-weight: 300;
    src: local('Cera Round Pro Light'), url('/fonts/CeraRoundProLight.woff') format('woff');
  }

  @font-face {
    font-family: 'Cera Round';
    font-style: normal;
    font-weight: 400;
    src: local('Cera Round Pro Regular'), url('/fonts/CeraRoundProRegular.woff') format('woff');
  }

  @font-face {
    font-family: 'Cera Round';
    font-style: normal;
    font-weight: 500;
    src: local('Cera Round Pro Medium'), url('/fonts/CeraRoundProMedium.woff') format('woff');
  }
    
  @font-face {
    font-family: 'Cera Round';
    font-style: normal;
    font-weight: bold;
    src: local('Cera Round Pro Bold'), url('/fonts/CeraRoundProBold.woff') format('woff');
  }
  
  @font-face {
    font-family: 'Cera Round';
    font-style: normal;
    font-weight: 900;
    src: local('Cera Round Pro Black'), url('/fonts/CeraRoundProBlack.woff') format('woff');
  }
`;

export default GlobalStyle;
