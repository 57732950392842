import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { BsFillStarFill } from 'react-icons/bs';

import EmptyHouse from '../../assets/EmptyHouse.svg';

import { formatNumberToPrice } from '../../utils/formatNumber';

import {
  PartyHouseCard,
  PartyHouseCardInfos,
  PartyHouseCardImage,
  PartyHouseCardPrice,
  PartyHouseCardFooter,
  PartyHouseRating,
} from './styles';

interface PartyHouseLargeCard {
  visible: boolean;
  item: any;
}

const PartyHouseLargeCard = ({ visible, item }: PartyHouseLargeCard): ReactElement => (
  <PartyHouseCard visible={visible}>
    <Link to={`/${item?.attributes?.slug}/${item?.id}`}>
      <PartyHouseCardImage src={EmptyHouse} />
      <PartyHouseCardInfos>
        <h3>{item?.attributes?.name || ''}</h3>
        <small>{`${item?.attributes?.address?.address}, ${item?.attributes?.address?.number} - ${item?.attributes?.address?.neighborhood}` || ''}</small>
        <p>
          {item?.attributes?.description || ''}
        </p>
        <PartyHouseCardFooter>
          <PartyHouseRating>
            <BsFillStarFill />
            {item?.rating || '5,0'}
            <span>
              -
              {' '}
              {item?.ratingCount || '0'}
              {' '}
              avaliações
            </span>
          </PartyHouseRating>
          <PartyHouseCardPrice>
            A partir de:
            <b>
              <em>{formatNumberToPrice(item?.attributes?.minimumPrice) || '00'}</em>
            </b>
          </PartyHouseCardPrice>
        </PartyHouseCardFooter>
      </PartyHouseCardInfos>
    </Link>
  </PartyHouseCard>

);

export default PartyHouseLargeCard;
