import React, {
  FormEvent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { RiCloseLine, RiShieldCheckFill, RiInformationLine } from 'react-icons/ri';

import { leadsService } from '../../services';

import {
  Container,
  ModalContent,
  ModalContentHeader,
  PartnerFormBox,
  InputControl,
  Toast,
  ModalSubHeader,
} from './styles';

interface IndicationFormModalProps {
  handleToggleModal: () => void;
}

interface FormInputValuesProps {
  name: string;
  instagram: string;
  referEmail: string;
  referName: string;
  status: string;
}

const IndicationFormModal = ({ handleToggleModal }: IndicationFormModalProps): ReactElement => {
  const [formInputValues, setFormInputValues] = useState({} as FormInputValuesProps);
  const [isVisibleToast, setIsVisibleToast] = useState<boolean>(false);
  const [toastType, setToastType] = useState<string>('error');

  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);

  function handleInputChange(event: any): void {
    const { name, value } = event?.target;

    setFormInputValues({
      ...formInputValues,
      [name]: value,
    });
  }

  async function handleLeadCreation(event: FormEvent): Promise<void> {
    event.preventDefault();
    const { instagram, ...formData } = formInputValues;

    try {
      await leadsService.create({
        ...formData,
        social: {
          instagram,
        },
        status: 'partner',
      });
      setToastType('success');
      setIsVisibleToast(true);
    } catch {
      setToastType('error');
      setIsVisibleToast(true);
    }

    setTimeout(async () => {
      setIsVisibleToast(false);
      handleToggleModal();
    }, 4500);
  }

  return (
    <Container>
      <ModalContent>
        <ModalContentHeader>
          <h2>Indique uma casa de festa!</h2>
          <RiCloseLine onClick={handleToggleModal} />
        </ModalContentHeader>
        <ModalSubHeader>
          Estamos começando nossa operação e você pode nos ajudar indicando locais de festas bacana para fazer parte da plataforma.
        </ModalSubHeader>

        <PartnerFormBox onSubmit={(e) => handleLeadCreation(e)}>
          <InputControl>
            <small>Nome da casa de festa</small>
            <input
              type="text"
              name="name"
              placeholder="Ex: Casa de Festa Sonho Feliz"
              required
              onChange={(e) => handleInputChange(e)}
            />
          </InputControl>

          <InputControl>
            <small>Usuário do Instagram</small>
            <input type="text" name="instagram" placeholder="@casadefestasonhofeliz" required onChange={(e) => handleInputChange(e)} />
          </InputControl>

          {/* <InputControl>
            <small>Cidade e estado da casa de festa</small>
            <input type="text" name="city" placeholder="Ex: Porto Alegre, RS" onChange={(e) => handleInputChange(e)} />
          </InputControl> */}

          <InputControl>
            <small>Seu nome</small>
            <input type="text" name="referName" placeholder="Maria Garcia" required onChange={(e) => handleInputChange(e)} />
          </InputControl>

          <InputControl>
            <small>Seu e-mail</small>
            <input type="email" name="referEmail" placeholder="Ex: mariagarcia@gmail.com" required onChange={(e) => handleInputChange(e)} />
          </InputControl>

          <button type="submit">Enviar!</button>
          <small>
            <RiShieldCheckFill />
            Dados seguros e fornecidos de forma anônima.
          </small>
        </PartnerFormBox>

        <Toast isVisible={isVisibleToast} toastType={toastType}>
          <RiInformationLine />
          {toastType === 'success' ? 'Valeu pela indicação! Nós vamos entrar em contato para adicioná-los na Plataforma' : 'Não foi possível realizar a indicação'}
        </Toast>
      </ModalContent>
    </Container>
  );
};

export default IndicationFormModal;
