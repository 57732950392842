import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const PartyHouseList = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  padding: 0 32px;
`;

export const PartyHouseListTab = styled.div`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.neutral10};
    margin: 0 0 26px 0;
    padding: 0 0 26px 0;
    color: ${theme.colors.neutral80};
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2rem;

    display: flex;
    align-items: center;

    > b {
      margin-right: 6px;
    }
  `};
`;

export const PartyHouseCardsList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
