import _ from 'lodash';
import qs from 'qs';
import { House, HouseSearchFilter } from '../types/house';
import { Entity } from '../types/strapi';
import api from './api';

export default {
  async get(filter: HouseSearchFilter = {}): Promise<Array<Entity<House>>> {
    try {
      delete api.defaults.headers.common.Authorization;

      const {
        city,
        type,
        date,
        shift,
        guests,
      } = _.omitBy(filter, _.isNil);

      const filters = {
        address: { city },
        // TODO: type??
        // activities: { type },
        packages: {
          start: {
            $lte: date,
          },
          expiration: { $gte: date },
          shift,
          type,
        },
        capacity: {
          adults: {
            $gte: guests,
          },
        },
        status: 'active',
      };

      return (
        await api.get('/houses', {
          paramsSerializer: (params) => qs.stringify(params),
          params: {
            populate: '*',
            filters,
          },
        })
      ).data?.data;
    } catch {
      throw new Error('Unable to list party houses!');
    }
  },

  async getOne(id: string | number): Promise<Entity<House>> {
    try {
      return (
        await api.get(`/houses/${id}`, {
          params: {
            populate: '*',
          },
        })
      ).data?.data;
    } catch {
      throw new Error('Unable to list a party house!');
    }
  },
};
