import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import Home from '../pages/Home';
import HouseDetail from '../pages/HouseDetail';
import DemoHouseDetail from '../pages/DemoHouseDetail';

const OtherRoutes: React.FC = () => (
  <BrowserRouter>
    <Route exact path="/" component={Home} />
    <Route exact path="/:slug/:id" component={HouseDetail} />
    <Route path="/:slug/:id/demo" component={DemoHouseDetail} />
  </BrowserRouter>
);

export default OtherRoutes;
