import _ from 'lodash';
import qs from 'qs';
import { Entity } from '../types/strapi';
import api from './api';

export default {
  async getOne(id: string | number): Promise<Entity<any>> {
    try {
      return (
        await api.get(`/packages/${id}?[populate][menus][populate][menu_items]=*`, {
          params: {
            populate: '*',
          },
        })
      ).data?.data;
    } catch {
      throw new Error('Unable to list a party house!');
    }
  },

  async get(filter = {} as any): Promise<Entity<any>> {
    try {
      const {
        type,
        date,
        shift,
        // guests,
      } = _.omitBy(filter, _.isNil);

      const filters = {
        start: {
          $lte: date,
        },
        expiration: { $gte: date },
        shift,
        type,
        // capacity: {
        //   adults: {
        //     $gte: guests,
        //   },
        // },
      };

      return (
        await api.get('/packages', {
          paramsSerializer: (params) => qs.stringify(params),
          params: {
            populate: '*',
            filters,
          },
        })
      ).data?.data;
    } catch {
      throw new Error('Unable to list a party house!');
    }
  },
};
