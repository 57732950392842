import React, {
  FormEvent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { RiCloseLine, RiShieldCheckFill, RiInformationLine } from 'react-icons/ri';

import { leadsService } from '../../services';

import {
  Container,
  ModalContent,
  ModalContentHeader,
  PartnerFormBox,
  InputControl,
  Toast,
  ModalSubHeader,
} from './styles';

interface PartnerFormProps {
  handleToggleModal: () => void;
}

interface FormInputValuesProps {
  name: string;
  instagram: string;
  phone: string;
  referEmail: string;
  referName: string;
  status: string;
}

const PartnerForm = ({ handleToggleModal }: PartnerFormProps): ReactElement => {
  const [formInputValues, setFormInputValues] = useState({} as FormInputValuesProps);
  const [isVisibleToast, setIsVisibleToast] = useState<boolean>(false);
  const [toastType, setToastType] = useState<string>('error');

  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);

  function handleInputChange(event: any): void {
    const { name, value } = event?.target;

    setFormInputValues({
      ...formInputValues,
      [name]: value,
    });
  }

  async function handleLeadCreation(event: FormEvent): Promise<void> {
    event.preventDefault();
    const { instagram, phone, ...formData } = formInputValues;

    try {
      await leadsService.create({
        ...formData,
        social: {
          instagram,
          phone,
        },
        status: 'partner',
      });
      setToastType('success');
      setIsVisibleToast(true);
    } catch {
      setToastType('error');
      setIsVisibleToast(true);
    }

    setTimeout(async () => {
      setIsVisibleToast(false);
      handleToggleModal();
    }, 4500);
  }

  return (
    <Container>
      <ModalContent>
        <ModalContentHeader>
          <h2>Seja um parceiro!</h2>
          <RiCloseLine onClick={handleToggleModal} />
        </ModalContentHeader>
        <ModalSubHeader>
          Quer aumentar a locação do seu espaço de festas? Então venha fazer parte da NIVIS
        </ModalSubHeader>

        <PartnerFormBox onSubmit={(e) => handleLeadCreation(e)}>
          <InputControl>
            <small>Nome da casa de festa</small>
            <input type="text" name="name" placeholder="Ex: Casa de Festa Sonho Feliz" required onChange={(e) => handleInputChange(e)} />
          </InputControl>

          {/* <InputControl>
            <small>Cidade e estado da casa de festa</small>
            <input type="text" name="name" placeholder="Ex: Porto Alegre, RS" required />
          </InputControl> */}

          <InputControl>
            <small>Usuário do Instagram</small>
            <input type="text" name="instagram" placeholder="@casadefestasonhofeliz" required onChange={(e) => handleInputChange(e)} />
          </InputControl>

          <InputControl>
            <small>Telefone (WhastApp)</small>
            <input type="text" name="phone" placeholder="Ex: 51 9 9999 9999" required onChange={(e) => handleInputChange(e)} />
          </InputControl>

          <InputControl>
            <small>E-mail para contato</small>
            <input type="email" name="referEmail" placeholder="Ex: casadefestasonhofeliz@gmail.com" required onChange={(e) => handleInputChange(e)} />
          </InputControl>

          <InputControl>
            <small>Responsável</small>
            <input type="text" name="responsible" placeholder="Ex: Carlos Garcia" required onChange={(e) => handleInputChange(e)} />
          </InputControl>

          <button type="submit">Enviar!</button>
          <small>
            <RiShieldCheckFill />
            Seus dados seguros conforme nossas políticas de privacidade.
          </small>
        </PartnerFormBox>

        <Toast isVisible={isVisibleToast} toastType={toastType}>
          <RiInformationLine />
          {toastType === 'success' ? 'Ficamos felizes pelo seu interesse! Já vamos entrar em contato com você.' : 'Erro ao enviar solicitação, tente novamente.'}
        </Toast>
      </ModalContent>
    </Container>
  );
};

export default PartnerForm;
