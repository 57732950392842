/* eslint-disable camelcase */
import React, {
  ReactElement,
  useEffect,
  useState,
} from 'react';

import { AiOutlineInfoCircle } from 'react-icons/ai';
import { RiCloseLine } from 'react-icons/ri';

import { packagesService } from '../../services';

import {
  Container,
  AllItemsModalContent,
  ModalHeader,
  ModalBody,
  Content,
  EmptyMessage,
} from './styles';

interface MenuItemsModalProps {
  handleClose: () => void;
  selectedPackage: string | number;
}

interface MenuItemsProps {
  id: number;
  attributes: {
    createdAt: string | Date;
    updatedAt: string | Date;
    name: string;
    observation: string;
    menu_items: any;
  }
}

const MenuItemsModal = ({
  handleClose,
  selectedPackage,
}: MenuItemsModalProps): ReactElement => {
  const [menuItems, setMenuItems] = useState<Array<MenuItemsProps>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getMenuInformations();
  }, []);

  async function getMenuInformations(): Promise<void> {
    setIsLoading(true);

    try {
      setMenuItems((await packagesService.getOne(selectedPackage))?.attributes?.menus?.data ?? []);
    } catch {
      // console.log('Unable to list a package menu items!');
    }

    setIsLoading(false);
  }

  return (
    <Container>
      <AllItemsModalContent>
        <ModalHeader>
          <h2>Cardápio</h2>

          <RiCloseLine onClick={handleClose} />
        </ModalHeader>
        <ModalBody>
          {menuItems?.length && !isLoading ? menuItems.map(({ id, attributes: { name, menu_items } }) => (
            <Content key={id}>
              <h2>{name || ''}</h2>
              {menu_items?.data?.length ? (
                <ul>
                  {menu_items?.data?.map(({ attributes: { name: itemName } }: any) => (
                    <li>{itemName}</li>
                  ))}
                </ul>
              ) : (
                <span>
                  <AiOutlineInfoCircle />
                  Nenhuma opção para esta categoria.
                </span>
              )}
            </Content>
          )) : isLoading && !menuItems?.length ? (
            <EmptyMessage>
              <h2>Carrengando cardápio da casa de festas..</h2>
            </EmptyMessage>
          ) : (
            <EmptyMessage>
              <h2>Nenhum cardápio cadastrado para o pacote selecionado.</h2>
            </EmptyMessage>
          )}
        </ModalBody>
      </AllItemsModalContent>
    </Container>
  );
};

export default MenuItemsModal;
