import styled, { css } from 'styled-components';

import ConfeteBG from '../../assets/ConfeteBG.png';

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 44px 32px;
    margin: 64px 0 0 0;
    height: 148px;
    position: relative;
    overflow: hidden;
    background-color: ${theme.colors.alternativeCold08};

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.1;
      background-image: url(${ConfeteBG});
      background-repeat: repeat;
      background-position: 50% 50%;
      background-size: 30%;
    }

    @media (max-width: 1340px) {
      height: unset;
      padding: 56px 32px;
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    > h3 {
      color: ${theme.colors.neutral11};
      font-weight: 900;
      font-size: 2.8rem;
      line-height: 3.2rem;
    }

    > button {
      margin-left: 32px;
      border-radius: 100px;
      background-color: ${theme.colors.neutral11};
      height: 60px;
      color: ${theme.colors.alternativeCold08};
      font-size: 2.4rem;
      line-height: 2.8rem;
      font-weight: 700;
      padding: 16px 24px;
      border: 1px solid ${theme.colors.alternativeCold08};

      transition: ${theme.transitions.default};

      &:hover {
        color: ${theme.colors.neutral11};
        background-color: transparent;
        border-color: ${theme.colors.neutral11};
      }
    }


    @media (max-width: 1340px) {
      display: flex;
      flex-direction: column;

      > h3 {
        max-width: 507px;
        text-align: center;
        margin-bottom: 32px;
      }

      > button {
        margin-left: 0;
      }
    }

    @media (max-width: 700px) {
      > h3 {
        max-width: 350px;
      }

      > button {
        font-size: 1.8rem;
        line-height: 1.8rem;
      }
    }
  `};
`;
