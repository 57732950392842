import moment from 'moment';

const monthNames = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
const dayOfWeek = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];

function getDaysBetweenDates(startDate: any, endDate: any): any {
  const now = startDate.clone(); const
    dates = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format('DD/MM/YYYY'));
    now.add(1, 'days');
  }

  return dates;
}

function getMonthName(day: Date): any {
  return monthNames[day.getMonth()];
}

function getInterval(): any {
  const startDate = moment(new Date());
  const endDate = new Date().setFullYear(new Date().getFullYear() + 1);

  return getDaysBetweenDates(startDate, new Date(endDate).setMonth(new Date(endDate).getMonth() - 1));
}

function createDateAsUTC(date: any | Date): any {
  const convertedDate = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
  return new Date(convertedDate);
}

export {
  getMonthName, dayOfWeek, getInterval, createDateAsUTC,
};
