import React from 'react';
import {
  RiWifiLine,
  RiWindyLine,
  RiRecycleLine,
  RiAirplayLine,
  RiVolumeUpLine,
  RiProjector2Line,
} from 'react-icons/ri';

const facilityIcons = Object.freeze({
  'icon-wifi': <RiWifiLine />,
  'icon-ac': <RiWindyLine />,
  'icon-cleaning': <RiRecycleLine />,
  'icon-tv': <RiAirplayLine />,
  'icon-speaker': <RiVolumeUpLine />,
  'icon-projector': <RiProjector2Line />,
});

export function getFacilityIcon(label?: string): JSX.Element {
  return (
    (label && (facilityIcons as Record<string, JSX.Element>)[label]) || <></>
  );
}
