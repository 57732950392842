import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);

  width: 100%;
  height: 100vh;
  z-index: 1000;

  display: flex;
  align-items: center;
`;

export const PreBookingModalContent = styled.div`
  ${({ theme }) => css`
    max-width: 787px;
    width: 100%;
    background-color: ${theme.colors.neutral11};
    margin: 0 auto;
    padding: 44px;
    border-radius: 24px;

    position: absolute;
    top: 30px;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    max-height: 100%;
    overflow-y: auto;

    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
      width: 0px;
      background-color: white;
    }
  `};
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 32px;

  ${({ theme }) => css`
    color: ${theme.colors.alternativeCold01};

    > h2 {
      font-weight: 900;
      font-size: 2.8rem;
      line-height: 3.2rem;
    }

    > svg {
      font-size: 2.8rem;
      cursor: pointer;
    }
  `};
`;

export const PartyHouseBox = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.alternativeCold01};
    border-radius: 10px;
    padding: 24px;
    margin-bottom: 24px;
  `};
`;

export const ModalBody = styled.div`
  ${({ theme }) => css`
    overflow-y: auto;
    max-height: calc(100vh - 210px);
    flex: 1;

    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
      width: 0px;
      background-color: white;
    }

    > button {
      width: 100%;
      background-color: ${theme.colors.alternativeCold01};
      height: 58px;
      border-radius: 10px;
      color: ${theme.colors.neutral11};
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 1.8rem;
      margin-top: auto;

      transition: ${theme.transitions.default};

      &:hover {
        opacity: 0.8;
      }
    }
  `};
`;

export const Content = styled.div`
  ${({ theme }) => css`
    padding: 24px;

    border: 1px solid ${theme.colors.neutral10};
    border-radius: 10px;

    &:not(:first-child){
      margin-top: 32px;
    }

    h2 {
      padding-bottom: 24px;

      font-size: 1.4rem;
      line-height: 1.8rem;

      color: ${theme.colors.neutral80};
    }

    ul {
      li {
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 400;

        color: ${theme.colors.neutral80};

        &:not(:last-child) {
          margin-bottom: 18px;
        }
      }
    }

    span {
      display: flex;
      justify-content: center;
      align-items: center;

      font-size: 1.4rem;
      line-height: 2rem;
      font-weight: 400;

      color: ${theme.colors.neutral30};

      > svg {
        margin-right: 10px;
      }
    }
  `};
`;

export const FinalValueInformations = styled.div`
  ${({ theme }) => css`
    background: rgba(236, 107, 30, 0.05);
    border-radius: 10px;
    color: ${theme.colors.alternativeHot01};
    padding: 22px 32px;
    height: 98px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    > h5 {
      display: flex;
      flex-direction: column;

      font-weight: 700;
      font-size: 2.4rem;
      line-height: 2.8rem;

      > small {
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2rem;
        margin-bottom: 6px;
      }
    }

    > p {
      font-size: 1.4rem;
      line-height: 2rem;
      text-align: center;
      margin: 0 auto;
      max-width: 460px;
    }
  `};
`;

export const ModalBodyMainTexts = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid #E5E5E5;
    padding: 0 0 24px 0;
    margin: 0 0 24px 0;
    color: ${theme.colors.neutral80};

    > h3 {
      display: flex;
      flex-direction: column;

      font-weight: 700;
      font-size: 2.4rem;
      line-height: 2.8rem;

      > small {
        font-size: 1.4rem;
        line-height: 1.8rem;
        margin-top: 9px;
        font-weight: 400;

        > b {
          font-weight: 700;
          margin-right: 4px;
        }
      }
    }
  `};
`;

export const ModalBodyRating = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    > small {
      margin-top: 14px;
      color: ${theme.colors.neutral30};
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
    }
  `};
`;

export const RatingStars = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.8rem;

    > svg {
      color: ${theme.colors.alternativeHot05};

      :first-child {
        margin-left: 10px;
      }

      :not(:last-child) {
        margin-right: 5px;
      }
    }
  `};
`;

export const PlanInformations = styled.div`
  ${({ theme }) => css`
    > h4 {
      color: ${theme.colors.alternativeCold01};
      font-size: 1.8rem;
      line-height: 1.8rem;
      font-weight: 700;
      margin-bottom: 12px;
    }

    > p {
      color: ${theme.colors.neutral80};
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 2rem;
    }
  `};
`;

export const PlanFilters = styled.ul`
  display: flex;
  align-items: center;

  margin-top: 24px;
`;

export const PlanFilterItem = styled.li`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;

    background: rgba(64, 78, 237, 0.05);
    border-radius: 10px;
    color: ${theme.colors.alternativeCold01};
    padding: 15px 18px;

    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;

    &:not(:last-child) {
      margin-right: 12px;
    }

    > small {
      font-weight: 700;
      line-height: 1.8rem;
    }
  `};  
`;
