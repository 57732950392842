import React, { ReactElement, useState } from 'react';

import IndicationFormModal from '../IndicationFormModal';

import { Container, Content } from './styles';

const IndicationHero = (): ReactElement => {
  const [isVisibleIndicationModal, setIsVisibleIndicationModal] = useState<boolean>(false);

  return (
    <Container>
      <Content>
        <h3>Conhece uma casa de festa e quer indicar ela pra aparecer aqui?</h3>
        <button type="button" onClick={() => setIsVisibleIndicationModal(true)}>Indicar uma casa de festa</button>
      </Content>

      {isVisibleIndicationModal && (
        <IndicationFormModal handleToggleModal={() => setIsVisibleIndicationModal(false)} />
      )}
    </Container>
  );
};

export default IndicationHero;
