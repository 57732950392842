import React, { ReactElement, useState } from 'react';

import LoginForm from '../LoginForm';
import PartnerForm from '../PartnerForm';
import IndicationFormModal from '../IndicationFormModal';

import LargeLogo from '../../assets/LargeLogo.png';

import {
  Container,
  Logo,
  RightActions,
  MainButtons,
  PrimaryButton,
} from './styles';

const Navbar = (): ReactElement => {
  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);
  const [isVisiblePartnerForm, setIsVisiblePartnerForm] = useState<boolean>(false);
  const [isVisibleIndicationForm, setIsVisibleIndicationForm] = useState<boolean>(false);

  return (
    <>
      <Container>
        <Logo href="/">
          <img src={LargeLogo} alt="Logo" />
        </Logo>

        <RightActions>
          <button type="button" onClick={() => setIsVisibleIndicationForm(true)}>Indicar uma casa de festa</button>

          <MainButtons>
            <button type="button" onClick={() => setIsVisibleModal(true)}>
              Entrar
            </button>
            <PrimaryButton onClick={() => setIsVisiblePartnerForm(true)}>Seja um parceiro</PrimaryButton>
          </MainButtons>
        </RightActions>
      </Container>

      {isVisibleModal && (
        <LoginForm
          handleToggleModal={() => setIsVisibleModal(!isVisibleModal)}
        />
      )}

      {isVisiblePartnerForm && (
        <PartnerForm
          handleToggleModal={() => setIsVisiblePartnerForm(!isVisiblePartnerForm)}
        />
      )}

      {isVisibleIndicationForm && (
        <IndicationFormModal
          handleToggleModal={() => setIsVisibleIndicationForm(!isVisibleIndicationForm)}
        />
      )}
    </>
  );
};

export default Navbar;
