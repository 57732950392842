import React, { ReactElement } from 'react';
import { RiCloseLine } from 'react-icons/ri';

import {
  Container,
  ModalContent,
  ModalContentHeader,
  SignInForm,
  InputControl,
} from './styles';

interface LoginFormProps {
  handleToggleModal: () => void;
}

const LoginForm = ({ handleToggleModal }: LoginFormProps): ReactElement => (
  <Container>
    <ModalContent>
      <ModalContentHeader>
        <h2>Entre ou cadastre-se</h2>
        <RiCloseLine onClick={handleToggleModal} />
      </ModalContentHeader>

      <SignInForm>
        <InputControl>
          <small>E-mail</small>
          <input type="email" placeholder="Ex: mariagarcia@gmail.com" required />
        </InputControl>

        <InputControl>
          <small>Senha</small>
          <input type="password" placeholder="**********" required />
        </InputControl>

        <button type="submit">Entrar!</button>
      </SignInForm>
    </ModalContent>
  </Container>
);

export default LoginForm;
