import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);

  width: 100%;
  height: 100vh;
  z-index: 1000;

  display: flex;
  align-items: center;
`;

export const ModalContent = styled.div`
  ${({ theme }) => css`
    border-radius: 24px;
    width: 100%;
    max-width: 627px;
    background-color: ${theme.colors.neutral11};
    padding: 44px;
    margin: 0 auto;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &::-webkit-scrollbar {
      width: 0px;
      background-color: white;
    }
  `};
`;

export const ModalContentHeader = styled.div`
  ${({ theme }) => css`
    width: 100%;
    color: ${theme.colors.alternativeCold01};

    display: flex;
    align-items: center;
    justify-content: space-between;

    > h2 {
      font-weight: 900;
      font-size: 2.8rem;
      line-height: 3.2rem;
    }

    > svg {
      font-size: 2.8rem;
      cursor: pointer;
    }
  `};
`;

export const PartnerFormBox = styled.form`
  ${({ theme }) => css`
    width: 100%;
    margin: 30px 0 0 0;

    overflow-y: auto;
    max-height: calc(100vh - 210px);

    &::-webkit-scrollbar {
      width: 0px;
      background-color: white;
    }

    > button {
      width: 100%;
      background-color: ${theme.colors.alternativeHot01};
      border-radius: 100px;
      height: 58px;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 1.8rem;
      color: ${theme.colors.neutral11};
      margin-top: 24px;

      display: flex;
      align-items: center;
      justify-content: center;

      transition: ${theme.transitions.default};

      &:hover {
        opacity: 0.8;
      }
    }

    > small {
      color: ${theme.colors.neutral20};
      font-weight: 700;
      font-size: 1.2rem;
      line-height: 1.8rem;
      margin-top: 24px;

      display: flex;
      align-items: center;
      justify-content: center;

      > svg {
        margin-right: 6px;
      }
    }
  `};
`;

export const InputControl = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
  
    > small {
      color: ${theme.colors.alternativeCold01};
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.6rem;
      margin-bottom: 12px;
    }

    > input {
      height: 58px;
      padding: 19px 24px;
      border-radius: 100px;
      border: 1px solid ${theme.colors.neutral20};

      transition: ${theme.transitions.default};

      &::placeholder {
        color: ${theme.colors.neutral20};

        transition: ${theme.transitions.default};
      }

      &:focus, :active {
        border-color: ${theme.colors.neutral80};

        &::placeholder {
          color: ${theme.colors.neutral80};
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  `};
`;

export const Toast = styled.div<{ isVisible?: boolean, toastType: string }>`
  ${({ theme, isVisible, toastType }) => css`
    position: absolute;
    top: 5px;
    left: 50%;

    transform: translate(-50%, -100%);
    opacity: 0;
    color: ${theme.colors.neutral11};
    border-radius: 6px;
    padding: 8px 12px;
    max-width: 80%;
    width: 100%;
    font-size: 1.3rem;
    font-weight: 400;
    cursor: pointer;
    line-height: 150%;
    transition: ${theme.transitions.default};

    display: flex;
    align-items: center;
    text-align: center;

    > svg {
      margin-right: 8px;
    }

    ${isVisible
    && css`
      opacity: 1;
      transform: translate(-50%, 0);
    `};

    ${toastType === 'success'
    && css`
      background: ${theme.colors.success};
      border: 1px solid ${theme.colors.success};
      box-shadow: 0px 0px 50px rgba(12, 184, 112, 0.2);
    `};

    ${toastType === 'error'
    && css`
      background: ${theme.colors.error};
      border: 1px solid ${theme.colors.error};
      box-shadow: 0px 0px 50px rgba(227, 86, 86, 0.2);
    `};
  `};
`;

export const ModalSubHeader = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.neutral80};
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2rem;
    margin-top: 8px;
    opacity: 0.5;
  `};  
`;
