import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);

  width: 100%;
  height: 100vh;
  z-index: 1000;

  display: flex;
  align-items: center;
`;

export const AllItemsModalContent = styled.div`
  ${({ theme }) => css`
    max-width: 787px;
    width: 100%;
    background-color: ${theme.colors.neutral11};
    margin: 0 auto;
    padding: 44px;
    border-radius: 24px;

    position: absolute;
    top: 30px;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    max-height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0px;
      background-color: white;
    }
  `};
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 32px;

  ${({ theme }) => css`
    color: ${theme.colors.alternativeCold01};

    > h2 {
      font-weight: 900;
      font-size: 2.8rem;
      line-height: 3.2rem;
    }

    > svg {
      font-size: 2.8rem;
      cursor: pointer;
    }
  `};
`;

export const ModalBody = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 210px);

  &::-webkit-scrollbar {
    width: 0px;
    background-color: white;
  }
`;

export const Content = styled.div`
  ${({ theme }) => css`
    padding: 24px;

    border: 1px solid ${theme.colors.neutral10};
    border-radius: 10px;

    &:not(:first-child){
      margin-top: 32px;
    }

    h2 {
      padding-bottom: 24px;

      font-size: 1.4rem;
      line-height: 1.8rem;

      color: ${theme.colors.neutral80};
    }

    ul {
      li {
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 400;

        color: ${theme.colors.neutral80};

        &:not(:last-child) {
          margin-bottom: 18px;
        }
      }
    }

    span {
      display: flex;
      justify-content: center;
      align-items: center;

      font-size: 1.4rem;
      line-height: 2rem;
      font-weight: 400;

      color: ${theme.colors.neutral30};

      > svg {
        margin-right: 10px;
      }
    }
  `};
`;

export const EmptyMessage = styled.div`
  ${({ theme }) => css`
    padding: 32px;
    border-radius: 10px;
    min-height: 120px;
    width: 100%;
    border: 1px solid #E5E5E5;

    display: flex;
    align-items: center;
    justify-content: center;

    > h2 {
      color: ${theme.colors.neutral80};
    }
  `};
`;
