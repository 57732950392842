const defaultTheme = {
  colors: {
    alternativeCold00: '#3E4395',
    alternativeCold01: '#404EED',
    alternativeCold02: '#0B869F',
    alternativeCold03: '#4587B9',
    alternativeCold04: '#4B97D0',
    alternativeCold05: '#55ACEE',
    alternativeCold06: '#74C9E1',
    alternativeCold07: '#1EA637',
    alternativeCold08: '#79DA85',
    alternativeHot00: '#EE3D53',
    alternativeHot01: '#EC6B1E',
    alternativeHot02: '#E62179',
    alternativeHot03: '#E2AC1E',
    alternativeHot04: '#D4D526',
    alternativeHot05: '#FFCC4D',
    neutral00: '#000000',
    neutral90: '#1A1A1A',
    neutral80: '#333333',
    neutral70: '#4D4D4D',
    neutral60: '#666666',
    neutral50: '#808080',
    neutral40: '#999999',
    neutral30: '#B3B3B3',
    neutral20: '#CCCCCC',
    neutral10: '#E5E5E5',
    neutral05: '#F2F2F2',
    neutral11: '#FFFFFF',
    success: '#0CB870',
    error: '#E35656',
  },
  transitions: {
    default: '300ms ease-in-out',
  },
};

export default defaultTheme;
