import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.neutral05};
    padding: 54px 32px;
  `};
`;

export const MenuItems = styled.div`
  display: flex;
  align-items: flex-start;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const ItemsColumn = styled.div`
  ${({ theme }) => css`
    > h3 {
      color: ${theme.colors.neutral70};
      font-size: 1.8rem;
      line-height: 1.8rem;
      font-weight: 700;
      margin-bottom: 32px;
    }
    
    :not(:last-child) {
      margin-right: 44px;
    }

    @media (max-width: 700px) {
      margin-bottom: 44px;
    }
  `};
`;

export const ListItems = styled.div``;

export const Item = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.neutral70};
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2rem;
    cursor: pointer;

    :not(:last-child) {
      margin-bottom: 24px;
    }
  `};
`;

export const Copyright = styled.div`
  ${({ theme }) => css`
    margin-top: 32px;
    padding-top: 32px;
    border-top: 1px solid ${theme.colors.neutral10};
  `};
`;

export const CopyItems = styled.ul`
  display: flex;

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const CopyItem = styled.li`
  ${({ theme }) => css`
    color: ${theme.colors.neutral70};
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2rem;

    display: flex;
    align-items: center;

    :not(:last-child) {
      margin-right: 12px;

      &::after {
        display: flex;
        content: '';
        width: 2px;
        height: 2px;
        margin-left: 12px;
        background-color: ${theme.colors.neutral20};
        border-radius: 50%;
      }


      @media (max-width: 700px) {
        margin-bottom: 26px;
        margin-right: unset;

        &::after {
          display: none;
        }
      }
    }
  `};
`;
