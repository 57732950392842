import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, .3);
  z-index: 1000;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalContent = styled.div`
  ${({ theme }) => css`
    border-radius: 24px;
    width: 100%;
    max-width: 540px;
    background-color: ${theme.colors.neutral11};
    padding: 44px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `};
`;

export const ModalContentHeader = styled.div`
  ${({ theme }) => css`
    width: 100%;
    color: ${theme.colors.alternativeCold01};

    display: flex;
    align-items: center;
    justify-content: space-between;

    > h2 {
      font-weight: 900;
      font-size: 2.8rem;
      line-height: 3.2rem;
    }

    > svg {
      font-size: 2.8rem;
      cursor: pointer;
    }
  `};
`;

export const SignInForm = styled.form`
  ${({ theme }) => css`
    width: 100%;
    margin: 40px 0 0 0;

    > button {
      width: 100%;
      background-color: ${theme.colors.alternativeHot01};
      border-radius: 100px;
      height: 58px;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 1.8rem;
      color: ${theme.colors.neutral11};
      margin-top: 24px;

      display: flex;
      align-items: center;
      justify-content: center;

      transition: ${theme.transitions.default};

      &:hover {
        opacity: 0.8;
      }
    }
  `};
`;

export const InputControl = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
  
    > small {
      color: ${theme.colors.alternativeCold01};
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.6rem;
      margin-bottom: 12px;
    }

    > input {
      height: 58px;
      padding: 19px 24px;
      border-radius: 100px;
      border: 1px solid ${theme.colors.neutral20};

      transition: ${theme.transitions.default};

      &::placeholder {
        color: ${theme.colors.neutral20};

        transition: ${theme.transitions.default};
      }

      &:focus, :active {
        border-color: ${theme.colors.neutral80};

        &::placeholder {
          color: ${theme.colors.neutral80};
        }
      }
    }

    &:first-child {
      margin-bottom: 24px;
    }
  `};
`;
