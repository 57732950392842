import React, { ReactElement } from 'react';

import {
  Container,
  MenuItems,
  ItemsColumn,
  ListItems,
  Item,
  Copyright,
  CopyItems,
  CopyItem,
} from './styles';

const Footer = (): ReactElement => (
  <Container>
    <MenuItems>
      <ItemsColumn>
        <h3>Atendimento</h3>
        <ListItems>
          <Item>Perguntas frequentes</Item>
          {/* <Item>Informações de segurança</Item>
          <Item>Opções de cancelamento</Item>
          <Item>Nossa resposta à pandemia de COVID-19</Item> */}
        </ListItems>
      </ItemsColumn>

      {/* <ItemsColumn>
        <h3>Comunidade</h3>
        <ListItems>
          <Item>Combate à discriminação</Item>
          <Item>Apoie uma casa de festa</Item>
        </ListItems>
      </ItemsColumn>

      <ItemsColumn>
        <h3>Reservas</h3>
        <ListItems>
          <Item>Reservar minha festa</Item>
          <Item>Indicar uma casa de festas</Item>
        </ListItems>
      </ItemsColumn>

      <ItemsColumn>
        <h3>Sobre</h3>
        <ListItems>
          <Item>Newsletter</Item>
          <Item>Carta dos nossos fundadores</Item>
          <Item>Carreiras</Item>
          <Item>Investidores</Item>
        </ListItems>
      </ItemsColumn> */}
    </MenuItems>

    <Copyright>
      <CopyItems>
        <CopyItem>© 2022 NIVIS - Todos os direitos reservados.</CopyItem>
        <CopyItem>Privacidade</CopyItem>
        <CopyItem>Termos</CopyItem>
        <CopyItem>Sobre a Nivis</CopyItem>
      </CopyItems>
    </Copyright>
  </Container>
);

export default Footer;
