import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import React, { ReactElement, useEffect, useState } from 'react';
import { RiSearchLine } from 'react-icons/ri';

import { Container, MainFilters, FilterControl } from './styles';
import { HouseSearchFilter } from '../../types/house';
import { filtersService } from '../../services';

interface Props {
  search: (filters: any) => void;
}

const Hero = ({ search }: Props): ReactElement => {
  const [partyDateType, setPartyDateType] = useState('text');
  const [filtersList, setFiltersList] = useState<any>([]);
  const initialValues: HouseSearchFilter = {
    city: '',
    type: '',
    date: '',
    guests: '',
    shift: '',
  };

  const validationSchema = Yup.object().shape({
    city: Yup.string().required('Preencha a cidade'),
    type: Yup.string().required('Preencha o tipo de festa'),
    date: Yup.date().required('Preencha a data'),
    guests: Yup.number().min(0).required('Preencha o Nº de convidados'),
    shift: Yup.string().required('Preencha o turno'),
  });

  const handleSubmit = (values: typeof initialValues): void => {
    search?.(values);
  };

  useEffect(() => {
    getFilters();
  }, []);

  const getFilters = async () : Promise<void> => {
    try {
      const filtersResponse = await filtersService.get();
      setFiltersList(filtersResponse?.attributes?.value || []);
    } catch {
      // console.log('Unable to list a filters from home!');
    }
  };

  return (
    <Container>
      {/* <small>Mais de 234 casas prontas para realizar sua festa!</small> */}
      <h1>
        Economizamos o seu tempo ⏱, te ajudando a encontrar o melhor lugar 📍
        para fazer o seu evento! 🎉
      </h1>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors }) => (
          <Form>
            <MainFilters>
              <FilterControl>
                <Field
                  as="select"
                  className={errors?.city ? 'error' : ''}
                  name="city"
                  placeholder="Cidade"
                >
                  <option value="" disabled>Cidade</option>
                  {filtersList?.cities?.map((city: any) => (
                    <option value={city} key={city}>{city}</option>
                  ))}
                  ;
                </Field>
              </FilterControl>
              <FilterControl>
                <Field
                  as="select"
                  className={errors.type ? 'error' : ''}
                  name="type"
                  type="text"
                  placeholder="Tipo de festa"
                >
                  <option value="" disabled>Tipo de Festa</option>
                  {filtersList?.typePackage?.map((type: any) => (
                    <option value={type} key={type}>{type}</option>
                  ))}
                  ;
                </Field>
              </FilterControl>
              <FilterControl>
                <Field
                  className={errors.date ? 'error' : ''}
                  name="date"
                  type={partyDateType}
                  placeholder="Data da festa"
                  onFocus={() => setPartyDateType('date')}
                  onBlur={(e: any) => (e?.target?.value === '' ? setPartyDateType('text') : setPartyDateType('date'))}
                />
              </FilterControl>
              <FilterControl>
                <Field
                  as="select"
                  className={errors.guests ? 'error' : ''}
                  name="guests"
                  type="number"
                  placeholder="Nº de convidados"
                >
                  <option value="" disabled>Nº de convidados</option>
                  {filtersList?.capacity?.map((capacity: any) => (
                    <option value={capacity} key={capacity}>{capacity}</option>
                  ))}
                  ;
                </Field>
              </FilterControl>
              <FilterControl>
                <Field
                  as="select"
                  className={errors.shift ? 'error' : ''}
                  name="shift"
                  type="text"
                  placeholder="Turno"
                >
                  <option value="" disabled>Turno</option>
                  {filtersList?.shifts?.map((shift: any) => (
                    <option value={shift?.name} key={shift?.value}>{shift?.name}</option>
                  ))}
                  ;
                </Field>
              </FilterControl>
              <button type="submit">
                <RiSearchLine />
              </button>
            </MainFilters>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default Hero;
