import React from 'react';
// import { useAuth } from '../contexts/AuthContext';

// import AuthenticatedRoutes from './AuthenticatedRoutes';
import OtherRoutes from './OtherRoutes';

const Routes: React.FC = () => <OtherRoutes />;
// const { signed } = useAuth(); <OtherRoutes />;

export default Routes;
